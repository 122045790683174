<template>
  <section class='section'>
    <!-- Modal -->
    <div class="modal" :class="{ 'is-active': modalActive }">
      <div class="modal-background"></div>
      <form class="modal-card" @submit.prevent="confirmChange">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-centered">Confirmar Cambio</p>
          <button class="delete" aria-label="close" type="reset" @click="closeModal"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-multiline is-centered">
            <div class="column is-11 field">
              <p>¿Quieres cambiar el estado de contacto?</p>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" type="reset" @click="closeModal">Cancelar</button>
          <button class="button is-success" type="submit">Confirmar</button>
        </footer>
      </form>
    </div>

    <modal-groups
      v-if="modalGroups"
      :close="showModal"
      :userGroupsList="userGroupsList"
      :createUserGroup="createUserGroup"
      :loadingAdd="loadingAdd"
    />
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Leads</h1>
        </div>
      </div>
      <div class="level-left">
      </div>
      <div class="level-right">
        <div class="level-item">
          <button
            class="button"
            type="button"
            :class="{ 'is-loading': isLoading }"
            @click="getCsvDownload"
          >
          Descargar
        </button>
        </div>
        <div class="level-item">
          <div class="control">
            <input class="input" type="text" v-model="search" @keyup="searchUser" placeholder="Buscador...">
          </div>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-12">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="leadsList"
          :loading=loading
        >
        <div
          slot="checkbox"
          slot-scope="scope"
        >
          <input class="checkbox" type="checkbox" :checked="scope.row.isAdminContacted" @click="showConfirmationModal(scope.row, $event)" /> <span class="has-tooltip-top" :data-tooltip="'Ultima modificacion de ' + scope.row.adminName">{{ scope.row.adminName }}</span>
        </div>
        <div id="urlLanding" slot="urlLanding" slot-scope="scope">
          <span style="margin-right: 10px;">{{ truncateUrl(scope.row.urlLanding, 20) }}</span>
          <button class="button is-small is-info" @click="copyToClipboard(scope.row.urlLanding)">Copiar</button>
        </div>
        </base-table>
      </div>
    </div>
    <base-pagination
      class="is-medium"
      :page="page"
      :pages="parseInt(pages)"
      :action="paginator"
      :loading="loading"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import mixin from '../../mixin/validateDate'

export default {
  mixins: [mixin],
  name: 'Leads',
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination'),
    ModalGroups: () => import('@/components/Customers/ModalGroups')
  },
  data () {
    return {
      search: '',
      page: 1,
      pages: '',
      loading: false,
      isLoading: false,
      leadsList: [],
      columnsData: [
        {
          id: 'id',
          header: 'Id',
          class: 'column-mobile'
        },
        { id: 'checkbox', name: 'checkbox', header: 'Contactado', class: 'column-mobile has-text-centered' },
        { id: 'nombreUsuario', header: 'Nombre usuario', class: 'column-mobile' },
        { id: 'correo', header: 'Correo', class: 'column-mobile' },
        { id: 'telefono', header: 'Télefono', class: 'column-mobile' },
        {
          id: 'fechaCreacion',
          header: 'Fecha',
          class: 'column-mobile',
          accessor: (row) => moment(row.fechaCreacion).format('DD-MM-YYYY')
        },
        { id: 'modelo', header: 'Modelo', class: 'column-mobile' },
        { id: 'marca', header: 'Marca', class: 'column-mobile' },
        { id: 'almacenamiento', header: 'Almacenamiento', class: 'column-mobile' },
        { id: 'color', header: 'Color', class: 'column-mobile' },
        { id: 'ciudad', header: 'Ciudad', class: 'column-mobile' },
        { id: 'urlLanding', name: 'urlLanding', header: 'Landing', class: 'column-mobile' }
      ],
      modalGroups: false,
      userGroupsList: [],
      loadingAdd: false,
      filterGroup: '',
      modalActive: false,
      currentRow: null
    }
  },
  methods: {
    ...mapMutations(['setAuthenticationValue']),
    ...mapActions(['getLeads', 'csvDownloadLeads', 'addTemporalNotification', 'updateLeadsAdminContacted']),
    paginator (page) {
      this.page = page
      this.loading = true

      const { search } = this.$router.history.current.query
      this.search = search === '' ? this.search : search

      this.$router.push({ name: 'leads', query: { page: page, filter: this.filterGroup, search: this.search } })

      this.getLeads({ page, search: this.search })
        .then(resp => {
          this.loading = false
          this.leadsList = resp.payload
          this.pages = resp.totalPages
        })
    },
    searchUser ({ target }) {
      this.page = 1
      if (target.value.length >= 3 || target.value.length === 0) {
        this.search = target.value
        this.$router.push({ name: 'usuarios', query: { page: this.page, search: target.value, filter: this.filterGroup } })
        this.getLeads({ page: this.page, search: target.value })
          .then(resp => {
            this.leadsList = resp.payload
            this.pages = resp.totalPages
            this.loading = false
          })
      }
    },
    showModal () {
      this.modalGroups = !this.modalGroups
    },
    filterByGroup ({ target }) {
      this.page = 1
      this.$router.push({ name: 'usuarios', query: { page: this.page, search: this.search, filter: target.value } })
      this.getLeads({ page: this.page, search: this.search, filterGroup: target.value })
        .then(resp => {
          this.leadsList = resp.payload
          this.pages = resp.totalPages
          this.loading = false
        })
    },
    getCsvDownload () {
      this.isLoading = true
      this.csvDownloadLeads({ search: this.search })
        .then(resp => {
          if (resp) {
            const exportName = 'Export users.csv'
            console.log(resp, 'test')
            const blob = new Blob([resp], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName)
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', exportName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
          } else {
            this.addTemporalNotification({ type: 'danger', message: 'Error en la descarga' })
          }
          this.isLoading = false
        })
    },
    showConfirmationModal (row, event) {
      event.preventDefault()
      this.currentRow = row
      this.modalActive = true
    },
    async confirmChange () {
      const { id, isAdminContacted } = this.currentRow
      await this.updateLeadsAdminContacted({ leadId: id, isContacted: !isAdminContacted })
      this.closeModal()

      this.getLeads({ page: this.page, search: this.search })
        .then(resp => {
          this.leadsList = resp.payload
          this.pages = resp.totalPages
          this.loading = false
        })
    },
    closeModal () {
      this.modalActive = false
      this.currentRow = null
    },
    copyToClipboard (text) {
      const textarea = document.createElement('textarea')
      textarea.value = text
      textarea.style.position = 'fixed'
      textarea.style.opacity = '0'
      document.body.appendChild(textarea)
      textarea.focus()
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      this.addTemporalNotification({ type: 'success', message: 'Texto copiado al portapapeles' })
    },
    truncateUrl (url, length) {
      return url.length > length ? url.substring(0, length) + '...' : url
    }
  },
  beforeMount () {
    this.loading = true
    const { page, filter, search } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.filterGroup = filter === '' || filter === undefined ? this.filterGroup : parseInt(filter, 10)
    this.search = search === '' ? this.search : search

    this.getLeads({ page: this.page, search: this.search })
      .then(resp => {
        this.leadsList = resp.payload
        this.pages = resp.totalPages
        this.loading = false
      })
  }
}
</script>

<style scoped>
h2 {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 700;
}
.section {
  width: 100%;
  padding: 0 20px 0 20px;
}
.checkbox {
  transform: scale(1.5);
  margin-right: 5px;
}
</style>
